import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { arbitrum, polygonMumbai, polygon } from "wagmi/chains";

// 1. Get projectId
const projectId = "5a840f1776423fbf135151ebc446a876";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [polygonMumbai];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <WagmiConfig config={wagmiConfig}>
    <App />
  </WagmiConfig>
  // <React.StrictMode>
  // </React.StrictMode>
);
