import logo from "./logo.svg";
import "./App.css";
import React, { useRef } from "react";

import badge from "./assets/images/badge.png";
import sticker from "./assets/images/sticker.png";
import logo1 from "./assets/images/logo.png";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { tdn_abi, tdn_contract_address } from "./TDN";
import { useAccount, useContract, useBalance, useConnect } from "wagmi";
let currentChainId = "0x13881";
let currentChainTitle = "Polygon Mumbai Testnet";
function App() {
  const w3mButtonRef = useRef(null);

  const [quantity, setQuantity] = useState(1);
  const [totalMinted, setTotalMinted] = useState("Loading...");
  const { address, isConnecting } = useAccount();
  const { connect: connectWallet } = useConnect();

  async function mintNFTs() {
    // alert("Minting NFTs !!");,
    try {
      let { signer, account } = await connect();
      if (!signer) return;
      console.log({ signer, account });
      const tdn_contract = new ethers.Contract(
        tdn_contract_address,
        tdn_abi,
        signer
      );
      alert("Minting " + quantity + " TDNs Now ....");
      let price = await tdn_contract.price();
      price = price / 10 ** 18;
      console.log("price is ", price);
      price = ethers.utils.parseEther("" + price * quantity);
      console.log("price is ", price);

      let tx = await tdn_contract.mintBulkTDN(account, quantity, {
        value: price,
      });
      alert("Transaction Initiated 🐅");
      await tx.wait();
      alert(quantity + " TDNs are minted ✅");
      fetchTotalMinted();
    } catch (error) {
      console.log({ error });
    }
  }
  async function connect() {
    try {
      let provider = window.ethereum;
      let accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      console.log({ accounts, provider });
      // console.log();
      // console.log(provider.getNetwork());

      if (provider.chainId != currentChainId) {
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: currentChainId }],
          });
        } catch (switchError) {
          console.log({ switchError });
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            alert("please install " + currentChainTitle + " in metamask !");
            return { signer: null };
            // try {
            //   await provider.request({
            //     method: "wallet_addEthereumChain",
            //     params: [
            //       {
            //         chainId: "0x13881",
            //         chainName: "Polygon Mumbai",
            //         rpcUrls: ["https://..."] /* ... */,
            //       },
            //     ],
            //   });
            // } catch (addError) {
            //   // handle "add" error
            // }
          }
          // handle other "switch" errors
        }
      }
      // getting signer
      provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      console.log(signer);
      return { signer, account: accounts[0] };
    } catch (e) {
      alert("Error in Connecting Wallet !!!");
      console.log(e);
      return null;
    }
  }
  async function fetchTotalMinted() {
    try {
      if (!address) return;
      let { signer, account } = await connect();
      if (!signer) return;
      const tdn_contract = new ethers.Contract(
        tdn_contract_address,
        tdn_abi,
        signer
      );
      let res = await tdn_contract.totalSupply();
      console.log("total suply ", res);
      setTotalMinted(parseInt(res));
    } catch (e) {}
  }

  useEffect(() => {
    fetchTotalMinted();
  }, [address]);

  const handleClickW3MButton = () => {
    const w3mButton = document.getElementById("w3m-button");

    if (w3mButton) {
      w3mButton.click();
    }
  };
  return (
    <>
      <div className="bg-black shad">
        <div className="max-w-6xl m-auto">
          <img
            src={logo1}
            alt=""
            className="w-full max-w-[10rem] cursor-pointer"
            onClick={() =>
              (window.location.href = "https://thedeluxnetwork.com")
            }
          />
        </div>
      </div>
      <div className="App py-16">
        <div className="box mx-4 py-8 px-5 lg:px-14 w-screen lg:w-[40vw]  bg-black relative">
          <div className="badge ">
            <img src={badge} alt="" className="left-[-1rem] lg:left-[-3rem]" />
          </div>
          <div className="pb-10">
            <img
              src={"./logo.png"}
              alt=""
              // style={{ width: "100%", maxWidth: "11rem" }}
              className="bg-[#111827] max-w-[9rem] rounded-md"
            />
          </div>
          <div style={{ color: "#fff" }} className="font-bold">
            <span className="opacity-50"> {totalMinted} /</span> 1500 minted
          </div>
          <div>
            <h1 className="text-white font-bold py-4 text-2xl">
              Lux Lions X TDN Pirates
            </h1>
            <p className="opacity-50 text-white text-sm">
              Lux Lions X TDN Pirates is a collection of 1500 unique Lux Lions X
              NFTs on the POLYGON blockchain, with each NFT accompanied by 5000
              TDN Tokens
            </p>
          </div>
          {/* <div className="vertical_space" /> */}

          <div className="form">
            <div className="input relative py-3">
              {/* <label htmlFor="quantity">Enter number of NFTs to Mint</label> */}
              <input
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                readOnly
                className="w-full bg-transparent px-3 text-white rounded-md py-[6px] text-center"
              />
              <div>
                <button
                  className="absolute bg-none outline-none text-white left-3 top-[11px] text-2xl"
                  onClick={() => setQuantity(quantity - 1)}
                >
                  -
                </button>
                <button
                  className="absolute bg-none outline-none text-white right-3 top-[11px] text-2xl"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </button>
              </div>
            </div>
            {/* <div className="vertical_space" /> */}

            {/* <div className="vertical_space" /> */}
            <div className="btn">
              {!address ? (
                <>
                  <div>
                    <w3m-button ref={w3mButtonRef} id="w3m-button" />
                  </div>
                  {/* <button
                  className=" bg-[#f59e0b] text-black rounded-md w-full py-1"
                  onClick={handleClickW3MButton}
                >
                  Connect wallet
                </button> */}
                </>
              ) : (
                <button
                  className=" bg-[#f59e0b] text-black rounded-md w-full py-1"
                  onClick={mintNFTs}
                >
                  Mint Now
                </button>
              )}
            </div>
            {/* <div className="vertical_space" /> */}
            {/* {address && (
            <div className="total_minted">
              Total NFTs Minted : {totalMinted}
            </div>
          )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
